@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: Imported;
  src: url(./Font/Futura\ Bold\ font.ttf);
}

@font-face {
  font-family: Imported;
  src: url(./Font/futura\ light\ bt.ttf);
}

@root{
  font-family: Imported;
}

@layer base {
  html {
    font-family: 'Futura';
  }
} */